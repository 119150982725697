/* typo utility classes */

// this will generate .typo-dm classes
@mixin typoSetUtilityClasses($font-sizes: (dm d1 d2 d3 d4 d5 d6 d7 b1 b2 b3 b4 b5 b6)) {
	@each $fsName in $font-sizes {
		.typo-#{$fsName} {
			@if (str-index($fsName, 'd') == 1) {
				@include typoDisplay($fsName, $ffHeading);
			} @else {
				@include typoBodycopy($fsName);
			}
		}
	}
}

/* display sizes */

// display mega
%typo-dm {
	font-size: rem-calc(30px);
	font-weight: $fwBold;
	line-height: 1.15;
	@include breakpoint(phone) {
		font-size: rem-calc(45px);
	}
	@include breakpoint(tablet-portrait) {
		font-size: rem-calc(55px);
	}
	@include breakpoint(tablet-landscape) {
		font-size: rem-calc(60px);
	}
	@include breakpoint(desktop) {
		font-size: rem-calc(80px);
	}
}
//h1 title size
%typo-d1 {
	font-size: rem-calc(22px);
	font-weight: $fwBold;
	line-height: 1.06;
	@include breakpoint(phone) {
		font-size: rem-calc(40px);
	}
	@include breakpoint(tablet-portrait) {
		font-size: rem-calc(45px);
	}
}
//h2 title size
%typo-d2 {
	font-weight: $fwBold;
	font-size: rem-calc(20px);
	line-height: 1.25;
	@include breakpoint(phone) {
		font-size: rem-calc(36px);
	}
}
//h3 title size
%typo-d3 {
	font-size: rem-calc(18px);
	font-weight: $fwBold;
	line-height: 1.35;
	@include breakpoint(phone) {
		font-size: rem-calc(30px);
	}
}

%typo-d4 {
	font-size: rem-calc(14px);
	font-weight: $fwRegular;
	line-height: 1.2;
	@include breakpoint(phone) {
		font-size: rem-calc(20px);
	}
}

%typo-d5 {
	font-family: $ffHeading;
	font-size: rem-calc(16px);
	font-weight: $fwRegular;
	letter-spacing: 0.02em;
	opacity: 0.9;
}

// Button font style
%typo-d6 {
	font-size: 14px;
	letter-spacing: 0.03em;
	font-weight: $fwSemiBold;
}

// Numbers
%typo-d7 {
	font-size: 40px;
	font-weight: $fwLight;
	@include breakpoint(phone) {
		font-size: rem-calc(50px);
	}
}

@mixin typoDisplay($font-size: d1, $font-family: $ffHeading) {
	@extend %typo-#{$font-size};
	font-family: $font-family;
}

/* display body copy */

// Perex
%typo-b1 {
	font-size: rem-calc(13px);
	line-height: 2;
	font-weight: $fwRegular;
}
// Labels font
%typo-b2 {
	font-size: rem-calc(12px);
	font-weight: $fwRegular;
}

// Side Text
%typo-b3 {
	font-size: rem-calc(16px);
	font-weight: $fwRegular;
	line-height: 1.5;
	@include letter-spacing(30);
}


// perex light
%typo-b4 {
	font-size: rem-calc(13px);
	font-weight: $fwLight;
	line-height: 2;
}

%typo-b5 {
	font-weight: $fwRegular;
	font-size: rem-calc(20px);
	line-height: 1.4;
	@include letter-spacing(35);
	@include breakpoint(phone) {
		font-size: rem-calc(30px);
	}
}

//reference quote
%typo-b6 {
	font-size: rem-calc(16px);
	line-height: 1.4;
	font-family: $ffHeading;
	font-weight: $fwLight;
	@include breakpoint(phone) {
		font-size: rem-calc(26px);
	}
	@include breakpoint(tablet-landscape) {
		font-size: rem-calc(40px);
	}
}


@mixin typoBodycopy($font-size: b1) {
	font-family: $ffBodycopy;
	@extend %typo-#{$font-size};
}

@mixin typoAnchors() {
	color: inherit;

	&:hover,
	&:active {
		// ...
	}
}

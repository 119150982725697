@mixin imgRetina($small, $retina, $width: auto, $height: auto, $path: '#{$pAssets}/img/') {
	background-image: url('#{$path}#{$small}');
	@if $width == cover or $width == contain {
		@if $width != false {
			background-size: $width;
		}
	} @else {
		@if $width != false {
			background-size: $width $height;
		}
	}
	@if $small != $retina {
		@media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {
			background-image: url('#{$path}#{$retina}');
		}
	}
}

@mixin bg($img, $width, $height) {
	width: ($width / 2);
	padding-top: ($height / 2);
	background: url('#{$pAssets}/img/#{$img}') no-repeat;
	background-size: ($width / 2) ($height / 2);
	height: 0;
	overflow: hidden;
}

@mixin paddingHeight($height) {
	padding-top: $height;
	overflow: hidden;
	height: 0;
}

@mixin addPreloadSpinner($variant: image, $cBg: $cBlue, $cIcon: $cWhite){
	&:before {
		content: '';
		@if ($variant == image) {
			position: relative;
			padding-top: $drPictures;
		} @else {
			@include position(absolute, 0);
		}
		display: block;
		z-index: 2;
		background: $cBg;
		opacity: 1;
		transition: opacity $tdDefault*4, background $tdDefault*2;
	}

	&:after {
		content: icon-char(spinner);
		@include position(absolute, 50% null null 50%);
		z-index: 3;
		font-family: svgicons;
		width: 40px;
		height: 40px;
		line-height: 40px;
		margin: -20px 0 0 -20px;
		font-size: 40px;
		text-align: center;
		color: $cIcon;
		animation: spinner 3s infinite;
		opacity: 1;
		transition: opacity $tdDefault*4, color $tdDefault*2;
	}

	&.is-loaded {
		&:after,
		&:before {
			opacity: 0;
		}
	}
}

@mixin transitionFix() {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}


// Create Aspect Ratio with padding-bottom on :pseudo element
@mixin aspectRatio($ratio, $pseudoElement: before) {
	$percent: #{$ratio * 100 * 1%};

	&:#{$pseudoElement} {
		display: block;
		width: 100%;
		content: '';
		padding-bottom: $percent;
	}

}

// IE 11 Vertical aligment - Workaround.
// Fix vertcal aligment when min-height is set.
@mixin ie-va-fix(){
	&:before {
		content:'';
		min-height:inherit;
		font-size:0;
	}
}

@mixin visual-angle($pos:'',$w:30px, $h:30px, $bg:$cWhite, $isBorder:false,$border: $cPrimary) {
	position: relative;
	overflow: hidden;
	&:before {
		@if($pos == '') {
			@include position(absolute, 0 0 null null);
			transform: translate(50%, -50%) rotate(-45deg);
		}
		@if($pos == 'bottom right') {
			@include position(absolute, null 0 0 null);
			transform: translate(50%, 50%) rotate(-45deg);
		}
		@if($pos == 'top right') {
			@include position(absolute, 0 0 null null);
			transform: translate(50%, -50%) rotate(-45deg);
		}
		background: $cWhite;
		width: $w;
		height: $h;
		content: '';
		@if $isBorder == true {
			border-left:1px solid $border;
		}
		transform-origin: center center;
		z-index: 5;
	}
}

@mixin openImage($pseudo: after, $dirr: right center) {
	&.show-me {
		&:#{$pseudo} {
			transition-duration: 0.6s;
			transform: scaleX(0);
		}
	}
	&:#{$pseudo} {
		@include position(absolute, 0);
		background: $cPrimaryLight;
		content: '';
		will-change: transform;
		transform-origin: right center;
	}
}

// mixin to convert value
@mixin letter-spacing($photoshop-value) {
	letter-spacing: $photoshop-value/1000 + em;
}

@mixin object-fit($size: contain, $position: center center){
	@include size(100%);
	object-fit: $size;
	object-position: $position;
}

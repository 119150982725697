// colors
$cBodycopy: #ffffff;
$cPrimary: #366072;
$cPrimaryLight: rgba($cPrimary, 57%);

$cGray-100: #D7D7D7;
$cGray-200: #555555;
$cGray-300: #8B8E8F;
$cGray-400: rgb(140, 140, 140);
$cGray-500: #AEB2B4;
$cWhite: #fff;
$cBlack: #000;

// borders
$brDefault:	 4px;



// typo (specific typo combination like headings or bodycopy define in `typo.scss`)
$fwBlack: 900;
$fwBold: 700;
$fwSemiBold: 600;
$fwMedium: 500;
$fwRegular: 400;
$fwLight: 300;

$ffBodycopy: 'Negramot', sans-serif;
$ffHeading: 'Negramot', sans-serif;

// vertical rythm
// use `spacer(lg);` custom scss function
$vrBase: 1rem; // expecting default browser font-size is 16px, ready to by changed
$vrSpacers: (
	1: $vrBase * .25, // 4px
	2: $vrBase * .5, // 8px
	standart: $vrBase * 1, // 16px
	context: $vrBase * 2.5, // 40px
	inner: $vrBase * 2, // 32px
	6: $vrBase * 4, // 64px
	7: $vrBase * 6, // 96px
	section: $vrBase * 8.125 // 130px

);

// dimensions
$dhHeader: 100px; // $dimensionHeightSomething
$borderTableStroke: 3px;

// transitions
$tdDefault: .2s;

// paths
$pAssets: '../../..';

//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid

@import 'util/util';

// 1. Global
// ---------

$global-font-size: 100%;
$global-width: 86.408%;
$global-text-direction: ltr;
$global-flexbox: true;
$print-transparent-backgrounds: true;

// Internal variables used for text direction
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 2. Breakpoints
// --------------
// https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// @media (max-width: 1200px) { ... } ==> @include breakpoint(tablet-landscape down) { ... }
$breakpoints: (
	tiny: 0,
	phone: 461px,
	tablet-portrait: 601px,
	tablet-landscape: 901px,
	desktop: 1201px,
	desktop-medium: 1501px,
	desktop-large: 1801px,
	desktop-ales: 2561px
);

$navigation: 901px;

$breakpoint-classes: ();

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
	tiny: 15px,
	tablet-landscape: 20px,
	desktop: 30px,
);

$grid-column-align-edge: true;
$block-grid-max: 12;

// 4. Responsive values

$responsive-vales-registrations: ();

//inicialization responsive-vales-registrations by breakpoints map
@each $key, $value in $breakpoints {
  $responsive-vales-registrations: recursive-map-merge($responsive-vales-registrations, (#{$key}: ())) !global;
}


$rv-example-top: (
		default: 40px,
		desktop-large: 30px,
		desktop: 20px,
		tablet-portrait: 10px,
		tablet-phone: 10px,
		tiny: 10px,
);


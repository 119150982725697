@import "global";

.dna-card {
  $this: &;
  display: flex;
  align-items: center;
  margin: #{spacer(section)} 0;
  justify-content: center;

  &--no-margin {
    margin: 0;
  }

  &__image {
    @include visual-angle('bottom right', 120px, 120px, $cWhite, false);
    @include flex-grid-column(4.5, 0);
    &--brief {
      @include visual-angle('bottom right', 120px, 120px, $cWhite, false);
    }
    &--video {
      &::before {
        display: none;
      }
    }

    &--six {
      @include flex-grid-column(6,false);
    }

    &--half {
      position: relative;
      width: 100%;
      @include flex-grid-column(12,false);
      @include aspectRatio(0.4704797048, after);
      &__image {
        @include visual-angle('top right', 120px, 120px, $cWhite, false);
      }

      picture {
        @include position(absolute, 0);
      }

      img {
        @include object-fit(cover);
      }
    }

    &--full {
      @include flex-grid-column(12,false);
    }
  }

  &__images {
    @include flex-grid-row();
    width: 100%;
  }

  &__content {
    @include flex-grid-column(6, 0);
  }

  &__perex {
    color: $cGray-400;
    margin-top: 20px;
    p {
      line-height: 2;
    }
  }

  &__cta {
    margin-top: spacer(context);
  }

  &--center {
    justify-content: space-between;
  }

  &--full {
    flex-direction: column;

    #{$this} {
      &__image {
        order: 1;
        margin-top: 60px;
        @include visual-angle('top right', 120px, 120px, $cWhite, false);
        &--special-margin {
          @include margin(130px null 0);
        }
        &--no-margin {
          margin: 0;
        }
      }

      &__content {
        @include flex-grid-column(12, false);
        margin: 0;
      }

      &__perex {
        @include flex-grid-column(12, false);
        margin: 20px auto 0;
      }
    }
  }

  @include breakpoint(tablet-portrait down) {
    flex-direction: column;
    &__content {
      @include margin(40px 0 0);
      @include flex-grid-column(12, 0);
    }
    &__image {
      @include flex-grid-column(12, 0);
      @include visual-angle('top right', 80px, 80px, $cWhite, false);
    }
    &--full {
      #{$this} {
        &__perex {
          @include flex-grid-column(12, 0);
        }
      }
    }
  }
  @include breakpoint(phone down) {
    margin: #{spacer(section)/2} 0;
    &__images {
      @include flex-grid-column(12, false);
      .column {
        @include flex-grid-column(12, 0);
        padding: 0;
      }
    }
    &__title {
      text-align: left;
    }
    &--no-margin {
      margin: 0;
    }
    &--full {
      flex-direction: column;

      #{$this} {
        &__image {
          margin-top: 30px;
          &--no-margin {
            margin: 0;
          }
        }
      }
    }
  }
}

.video-section {
  width: 100%;
  min-height: 370px;
  padding: 50px 0;

  @include breakpoint(900px up) {
    width: 70%;
  }

  @include breakpoint(phone down) {
    min-height: 220px;
  }
}

.video-detail {
  min-height: 370px;
  width: 100%;

  @include breakpoint(phone down) {
    min-height: 220px;
  }
}